<template>
  <div class="CatalogCategoriesPromo">
    <div class="CatalogCategoriesPromo__title">
      Каталог товаров
    </div>
    <p class="CatalogCategoriesPromo__subtitle">
      Полноценный каталог товаров доступен <br class="visible-mobile">
      сразу после заключения договора
    </p>
    <ul class="CatalogCategoriesPromo__list">
      <li class="CatalogCategoriesPromo__item">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-1.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Бытовая и компьютерная <br class="visible-tablet visible-mobile">
          техника, мебель
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item hidden-phone">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-2.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Расходные материалы
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-3.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Строительное <br class="visible-tablet">
          оборудование <br class="visible-small-desktop visible-tablet visible-mobile">
          и материалы
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item hidden-phone">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-4.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Аппаратура наблюдения <br class="visible-tablet">
          и контроля
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-5.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Хозяйственные,<br class="visible-tablet visible-mobile">
          канцелярские товары <br class="visible-small-desktop visible-tablet visible-mobile">
          и инвентарь
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item hidden-phone">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-6.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Электросварочное <br class="visible-small-desktop visible-tablet">
          и газопламенное<br class="visible-tablet">
          оборудование
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-7.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Инструменты и<br class="visible-tablet">
          спецоснастка
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item hidden-phone">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-8.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Кабельно-проводниковая<br class="visible-tablet">
          продукция
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-9.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Электротехнические<br class="visible-tablet visible-mobile">
          материалы
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item hidden-phone">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-10.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          АКБ, ЗВУ, инструменты<br class="visible-tablet">
          для ремонта шин
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item">
        <div class="CatalogCategoriesPromo__img">
          <MNuxtImg src="/images/main/catalog-11.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Спецодежда и обувь
        </div>
      </li>
      <li class="CatalogCategoriesPromo__item CatalogCategoriesPromo__item--accent">
        <div class="CatalogCategoriesPromo__img-bg">
          <MNuxtImg src="/images/main/catalog-12.png" />
        </div>
        <div class="CatalogCategoriesPromo__desc">
          Более 3 000<br class="visible-tablet visible-mobile">
          товарных<br class="visible-large-desktop visible-small-desktop">
          категорий<br class="visible-tablet visible-mobile">
          в каталоге
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
@import 'shared/assets/styles/base/common/variables';

.CatalogCategoriesPromo {
  $s: &;
  $border-color: $gray-200;
  margin-bottom: 80px;

  #{$s}__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
    padding-left: 32px;
    color: $text-black;
  }

  #{$s}__subtitle {
    font-size: 16px;
    line-height: 24px;
    color: $text-dark-green;
    margin-bottom: 40px;
    padding-left: 32px;
  }

  #{$s}__list {
    border-left: 1px solid $border-color;
    border-top: 1px solid $border-color;
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    overflow: hidden;

    list-style-type: none;
    margin: 0;
    padding: 0;

    @include maxw(1599) {
    }

    @include maxw(1279) {
      grid-template-columns: repeat(2, 50%);
      margin-left: 4px;
      margin-right: 4px;
    }

    @include maxw(767) {
      grid-template-columns: repeat(1, 100%);
    }
  }

  #{$s}__item {
    display: flex;
    align-items: center;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 32px;
    overflow: hidden;

    #{$s}__img {
      margin-right: 32px;
    }

    #{$s}__desc {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: $text-dark-green;
    }

    &:last-child {
      border-bottom-right-radius: 8px;
    }

    &#{$s}__item--accent {
      border-bottom-right-radius: 8px;
      background: linear-gradient(107.81deg, #50d04d 0%, #00a77b 95.12%);
      padding: 0;
      position: relative;
      padding-right: 32px;
      padding-left: 144px;

      #{$s}__img-bg {
        position: absolute;
        top: 0;
        left: 0;
      }

      #{$s}__desc {
        color: $white;
      }

      @include maxw(1599) {
        padding-left: 134px;
        padding-right: 16px;

        #{$s}__desc {
          font-size: 20px;
          line-height: 28px;
        }
      }

      @include maxw(1279) {
        #{$s}__img-bg {
          position: absolute;
          top: 0;
          left: 0;
        }

        #{$s}__desc {
          width: auto;
          font-size: 16px;
          line-height: 24px;
        }
      }

      @include maxw(767) {
        height: 120px;

        #{$s}__img-bg {
          height: 120px;
          width: 94px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    @include maxw(1599) {
      padding: 35px 24px 32px;
    }

    @include maxw(1279) {
      padding: 32px 16px;

      #{$s}__img {
        margin-right: 32px;
        width: 80px;
      }

      #{$s}__desc {
        font-size: 16px;
        line-height: 24px;
      }
    }

    @include maxw(767) {
      padding: 20px;

      #{$s}__img {
        margin-right: 20px;
        width: 60px;
      }
    }
  }

  @include maxw(1599) {
    margin-bottom: 60px;

    #{$s}__title {
      margin-bottom: 12px;
      padding-left: 24px;
    }

    #{$s}__subtitle {
      padding-left: 24px;
    }
  }

  @include maxw(1279) {
    margin-bottom: 48px;

    #{$s}__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px;
      padding-left: 20px;
    }

    #{$s}__subtitle {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
      padding-left: 20px;
    }
  }
}
</style>
