<template>
  <div class="CompanyBenefits">
    <div class="CompanyBenefits__col CompanyBenefits__col--main">
      <div class="CompanyBenefits__title">
        Делаем закупки быстрее и проще
      </div>
      <div class="CompanyBenefits__text">
        Улучшение закупочных процессов стандартизированной номенклатуры с выгодой для всех участников
      </div>
    </div>
    <div class="CompanyBenefits__col">
      <div class="CompanyBenefits__subtitle">
        Для крупных промышленных компаний, холдингов и корпораций
      </div>
      <ol class="CompanyBenefits__list">
        <li>
          Повышаем операционную эффективность<br>
          за счет цифровизации
        </li>
        <li>
          Снижаем стоимость закупок<br>
          за счет консолидации.
        </li>
        <li>Значительно расширяем ассортимент <br>и количество поставщиков</li>
      </ol>
      <div class="CompanyBenefits__btn">
        <MButton
          :type="EButtonTypes.Fill"
          :size="EButtonSizes.Medium"
          :icon-right="EIconPath.NavigationMainArrowSvg"
          @click="onClientCreateClick()"
        >
          Стать покупателем
        </MButton>
      </div>
    </div>
    <div class="CompanyBenefits__col">
      <div class="CompanyBenefits__subtitle">
        Для дистрибьютеров, производителей и поставщиков
      </div>
      <ol class="CompanyBenefits__list">
        <li>Обеспечиваем прямой выход на закупки крупнейших корпоративных клиентов</li>
        <li>Упрощаем работу: 1 договор, 1 квалификация, электронный документооборот</li>
        <li>Помогаем в цифровизации контента <br>и работе в онлайн</li>
      </ol>

      <div class="CompanyBenefits__btn">
        <MButton
          :type="EButtonTypes.Fill"
          :size="EButtonSizes.Medium"
          :icon-right="EIconPath.NavigationMainArrowSvg"
          @click="onSupplierRegistration()"
        >
          Стать поставщиком
        </MButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EButtonSizes, EButtonTypes, EIconPath } from '@maksmart/maksify/dist/types';
import { EClientApprovalType } from '~/enums/client/clientApprovalType.enum';

const { onSupplierRegistration } = useSupplierRegistration();
const { onCLickClientApprovalModal } = useClientApproval();
function onClientCreateClick() {
  onCLickClientApprovalModal(EClientApprovalType.Client);
}
</script>

<style lang="scss" scoped>
.CompanyBenefits {
  --grid-gap: 60px;
  --padding-Y: 40px;
  --border-color: #{$color-gray-light};

  padding: var(--padding-Y) var(--container-padding-X);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--grid-gap);

  background-image: url('shared/public/images/main/features-bg.png');
  background-repeat: no-repeat;
  background-position: 10px -18px;

  &__col {
    display: flex;
    flex-direction: column;

    &:after {
      content: '';
      flex-grow: 1;
    }
  }

  &__btn {
    order: 1;
  }

  &__title {
    @include text-m48;
    margin-bottom: 40px;
  }

  &__subtitle {
    @include font-m4;
    margin-bottom: 48px;
  }

  &__text {
    @include font-r4;
  }

  &__list {
    counter-reset: listCounter;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 64px;

    li {
      position: relative;
      padding-left: 40px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &::before {
        left: 0;
        top: 0;
        @include font-m3;
        color: $color-accent;
        counter-increment: listCounter;
        content: counter(listCounter, decimal-leading-zero) '. ';
        position: absolute;
        width: 32px;
      }
    }
  }

  @include maxw(1599) {
    --grid-gap: 40px;

    &__title {
      margin-bottom: 28px;
    }

    &__subtitle {
      margin-bottom: 48px;
    }

    &__list {
      margin-bottom: 48px;
    }
  }

  @include maxw(1279) {
    grid-template-columns: repeat(2, 1fr);
    background: none;
    --grid-gap: 64px;

    &__col {
      // только для планшета
      @include minw(768) {
        &--main {
          grid-column: 1 / 3;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: calc(-1 * var(--container-padding-X));
            right: calc(-1 * var(--container-padding-X));
            bottom: calc(-0.5 * var(--grid-gap));
            border-bottom: 1px solid var(--border-color);
          }
        }

        &:last-child {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: calc(-0.5 * var(--grid-gap));
            bottom: calc(-1 * var(--padding-Y));
            top: calc(-0.5 * var(--grid-gap));
            border-left: 1px solid var(--border-color);
          }
        }
      }
    }

    &__title {
      margin-bottom: 16px;
    }

    &__subtitle {
      margin-bottom: 32px;
    }

    &__list {
      margin-bottom: 32px;

      li:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  @include maxw(767) {
    grid-template-columns: repeat(1, 1fr);
    background: none;
    --grid-gap: 48px;
    --padding-Y: 24px;
    &__col {
      &:not(:last-child) {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: calc(-1 * var(--container-padding-X));
          right: calc(-1 * var(--container-padding-X));
          bottom: calc(-0.5 * var(--grid-gap));
          border-bottom: 1px solid var(--border-color);
        }
      }
    }

    &__subtitle {
      margin-bottom: 24px;
    }
  }
}
</style>
