<template>
  <BaseModal
    :model-value="modelValue"
    content-class="video-modal-content"
    classes="video-modal"
    overlay-class="video-overlay"
    @click.stop="onOverlayClick"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <button
      class="btn btn-secondary"
      @click="$emit('close')"
    >
      Закрыть
      <SvgIcon
        class="close-icon"
        :src="isMobile ? 'navigation/close-36px' : 'navigation/close-20px'"
      />
    </button>
    <div class="video-wrapper">
      <iframe
        src="https://www.youtube.com/embed/qdpfUM5JFsg?autoplay=1"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title="Обзор маркетплейса Максмарт"
      />
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import BaseModal from 'shared/components/modals/BaseModal.vue';
import WindowWidthManager from 'shared/services/windowWidth.service';

defineProps<{
  modelValue?: boolean;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'update:modelValue', value: boolean);
}>();

const { isMobile } = WindowWidthManager.getAdaptivaRefs();

function onOverlayClick() {
  emits('close');
}
</script>

<style lang="scss" scoped>
@import 'shared/assets/styles/base/common/variables';

$root: '.video-modal';

:deep(.video-modal) {
  #{$root}-content {
    height: 100%;
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;

    .btn {
      margin-left: auto;
    }

    .video-wrapper {
      margin: 20px auto 0;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      iframe {
        max-height: 713px;
        height: 100%;
        width: 1266px;
      }
    }
  }

  @media only screen and (max-width: 1599px) {
    #{$root}-content {
      .video-wrapper {
        iframe {
          max-height: 675px;
          height: 100%;
          width: 1200px;
        }
      }
    }
  }

  @media only screen and (max-width: 1279px) {
    #{$root}-content {
      .video-wrapper {
        iframe {
          max-height: 387px;
          height: 100%;
          width: 688px;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    #{$root}-content {
      padding: 0;

      .video-wrapper {
        margin-top: 0;

        iframe {
          max-height: 169px;
          height: 100%;
          width: 100%;
          margin-top: -56px;
        }
      }
    }

    .btn-secondary {
      padding: 0;
      font-size: 0;
      margin-top: 20px;
      margin-right: 20px;

      svg {
        margin: 0;
      }
    }
  }
}


</style>
