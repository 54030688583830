<template>
  <picture v-if="propsNuxtImg.src">
    <source
      v-for="(source, i) in props.sources"
      :key="i"
      :srcset="source.src"
      :media="source.media"
    >
    <NuxtImg v-bind="propsNuxtImg" />
  </picture>
</template>

<script setup lang="ts">
interface INuxtImg {
  src?: string;
  width?: string | number;
  height?: string | number;
  fit?: string;
  format?: string;
  provider?: string;
  preset?: string;
  densities?: string;
  modifiers?: _VTI_TYPE_Record;
  sizes?: string | _VTI_TYPE_Record;
  quality?: string | number;
  preload?: boolean;
  background?: string;
  alt?: string;
  referrerpolicy?: string;
  usemap?: string;
  longdesc?: string;
  ismap?: boolean;
  loading?: 'lazy' | 'eager';
  crossorigin?: boolean | 'anonymous' | 'use-credentials';
  decoding?: 'auto' | 'async' | 'sync';
  nonce?: string;
  placeholder?: string | number | boolean | unknown[];
  error?: ((...args: unknown[]) => unknown) | undefined;
  load?: ((...args: unknown[]) => unknown) | undefined;
  sources?: INuxtImgSource[];
}
import _ from 'lodash-es'
const props = withDefaults(defineProps<INuxtImg>(), {
  alt: '',
  format: 'webp',
  densities: 'x1',
});
const propsNuxtImg = computed(() => _.omit(props, ['sources']));
</script>

<style lang="scss" scoped>
picture,
img {
  max-width: 100%;
  max-height: 100%;
}
</style>
