import { RedirectManager } from 'utils/redirectManager.util';
import { CookieManager } from 'shared/utils/cookieManager.util';
import { ECookieKeys } from 'shared/enums/cookieKeys';
import { ERedirectActions } from 'shared/enums/redirectActions.enum';
import { ERedirectErrors } from 'shared/enums/redirectErrors.enum';
import Notificator from 'shared/services/notificator.service';

export function useShowRedirectNotification(): void {
  const notificator = process.client ? inject<Notificator>(Notificator.getServiceName()) : undefined;

  const redirectAction = CookieManager.getCookieObject(ECookieKeys.RedirectAction) as ERedirectActions;
  const redirectError = CookieManager.getCookieObject(ECookieKeys.RedirectError) as ERedirectErrors;

  if (process.client) {
    RedirectManager.showSignupNotification({ redirectAction, redirectError }, notificator);
  }
}
