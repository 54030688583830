<template>
  <div class="ClientBenefits">
    <div class="m-container ClientBenefits__header">
      <div class="ClientBenefits__header-content">
        <div class="title-1 ClientBenefits__header-title">
          {{ data.title }}
        </div>
        <div class="text-r4 ClientBenefits__header-subtitle">
          {{ data.text }}
        </div>
      </div>
      <div class="text-r4 ClientBenefits__header-action">
        <MButton
          :type="MM.EButtonTypes.Text"
          :theme="EButtonThemes.Primary"
          :icon-left="EIconPath.ActionPlusSvg"
          @click="data.action.onClick"
        >
          {{ data.action.label }}
        </MButton>
      </div>
    </div>
    <div class="m-container-offset">
      <div class="ClientBenefits__list">
        <div
          v-for="(item, ndx) in data.list"
          :key="ndx"
          class="ClientBenefits__card"
        >
          <BaseImg
            v-if="item.img"
            :src="item.img"
            class="ClientBenefits__card-img"
          />
          <div class="ClientBenefits__card-title">
            {{ item.title }}
          </div>
          <div class="ClientBenefits__card-text">
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="ClientBenefits__footer">
        <div class="ClientBenefits__footer-nav">
          <MSliderNavigation
            v-if="sliderBrands"
            :type="ESliderNavType.ArrowsSide"
            :slider="sliderBrands"
          />
        </div>
        <div class="ClientBenefits__brands">
          <div class="ClientBenefits__brands-prev">
            <MButton
              v-if="sliderBrands"
              is-rounded
              is-square
              :type="EButtonTypes.Fill"
              :theme="EButtonThemes.White"
              :size="EButtonSizes.Large"
              :icon="EIconPath.NavigationMainArrowSvg"
              :disabled="isPrevDisabled"
              @click="sliderBrands.slidePrev()"
            />
          </div>
          <div class="ClientBenefits__brands-slider">
            <MSlider
              :swiper="{
                speed: 800,
                slidesPerView: 1,
                slidesPerGroupAuto: false,
                autoplay: {
                  delay: 6000,
                },
                breakpoints: {
                  769: {
                    slidesPerView: 'auto',
                    slidesPerGroupAuto: true,
                    autoplay: false,
                  },
                },
              }"
              @slider-init="onInitSlider"
            >
              <MSliderItem
                v-for="(brand, idx) in data.brands"
                :key="idx"
                class="ClientBenefits__brands-item ClientBenefits__brands-item--default"
              >
                <MNuxtImg
                  v-if="brand.img"
                  :src="brand.img"
                />
              </MSliderItem>
              <MSliderItem
                v-for="(brandBatch, idx) in brandsMobile"
                :key="idx"
                class="ClientBenefits__brands-item ClientBenefits__brands-item--mobile"
              >
                <div
                  v-for="(brand, i) in brandBatch"
                  :key="i"
                  class="ClientBenefits__brands-img"
                >
                  <MNuxtImg
                    v-if="brand.img"
                    :src="brand.img"
                  />
                </div>
              </MSliderItem>
            </MSlider>
          </div>
          <div class="ClientBenefits__brands-next">
            <MButton
              v-if="sliderBrands"
              is-rounded
              is-square
              :type="EButtonTypes.Fill"
              :theme="EButtonThemes.White"
              :size="EButtonSizes.Large"
              :icon="EIconPath.NavigationMainArrowSvg"
              :disabled="isNextDisabled"
              @click="sliderBrands.slideNext()"
            />
          </div>
        </div>

        <div class="ClientBenefits__footer-action">
          <MButton
            :type="EButtonTypes.Fill"
            full-width
            :size="MM.EButtonSizes.Medium"
            @click="data.action.onClick"
          >
            {{ data.action.label }}
          </MButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EButtonThemes, EButtonTypes, EIconPath, ESliderNavType, EButtonSizes } from '@maksmart/maksify/dist/types';

import _ from 'lodash-es';
import { MM } from 'types';
import MNuxtImg from 'components/MNuxtImg/MNuxtImg.vue';

const props = defineProps<{
  data: {
    title: string;
    text: string;
    footerTitle: string;
    list: Array<{
      img: string;
      title: string;
      text: string;
    }>;
    brands: Array<{
      img: string;
    }>;
    action: {
      label: string;
      onClick: () => void;
    };
  };
}>();

const sliderBrands = ref();

// Бренда разбитые на пачки по 4 штуки
const brandsMobile = computed(() => _.chunk(props.data.brands, 4));

const isPrevDisabled = ref(true);
const isNextDisabled = ref(false);

const onInitSlider = (slider) => {
  sliderBrands.value = slider;

  sliderBrands.value.on('slideChange', () => {
    isPrevDisabled.value = false;
    isNextDisabled.value = false;
  });
  sliderBrands.value.on('reachBeginning', async () => {
    await nextTick(); // после обнуления значений обычной смены слайдов
    isPrevDisabled.value = true;
  });
  sliderBrands.value.on('reachEnd', async () => {
    await nextTick(); // после обнуления значений обычной смены слайдов
    isNextDisabled.value = true;
  });
};
</script>

<style lang="scss" scoped>
$border-color: $color-gray-light;

.ClientBenefits {
  &__header {
    display: flex;

    &-content {
      flex: 1 1 auto;
    }

    &-title {
      margin-bottom: 16px;
    }

    &-subtitle {
      margin-bottom: 40px;
    }

    &-action {
      flex: 0 0 auto;
      margin-left: 40px;
      padding-top: 8px;
    }
  }

  &__list {
    display: flex;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    border-radius: 8px 8px 0 0;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      border: 1px solid $border-color;
      border-radius: 8px 8px 0 0;
    }
  }

  &__card {
    flex: 0 0 20%;
    padding: 32px var(--container-offset-X);

    &:not(:last-child) {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;

        border-right: 1px solid $border-color;
      }
    }

    &-img {
      margin-top: -32px;
      margin-left: -32px;
      width: 136px;
      height: 136px;
      margin-bottom: 16px;
    }

    &-title {
      @include font-m4;

      // TODO: вынести этот цвет в библиотеку
      color: #214237;
      margin-bottom: 16px;
    }

    &-text {
      color: $color-secondary;
    }
  }

  &__footer {
    background-color: $color-gray-extra-light;
    border: 1px solid $border-color;
    border-top: none;
    padding: 32px var(--container-offset-X);
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    grid-gap: 40px;

    &-title {
      @include font-m4;

      margin-right: 80px;
    }

    &-nav {
      display: flex;
      justify-content: flex-end;
      flex: 1 0 auto;
      display: none;

      &:empty {
        display: none;
      }
    }

    &-action {
      display: none;
    }
  }

  &__brands {
    user-select: none;
    transition: 0.2s;
    max-width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 40px;

    &-prev,
    &-next {
      flex: 0 0 auto;
    }

    &-prev {
      transform: rotate(180deg);
    }

    &-slider {
      flex: 1 1 auto;
      max-width: calc(100% - 88px * 2);
    }

    &._hide {
      opacity: 0;
    }

    &-item {
      width: auto;
      flex: 0 0 auto;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        padding-right: 80px;
      }
      :deep(img) {
        max-width: 100%;
      }

      &--mobile {
        display: none;
      }
    }

    :deep(.swiper-wrapper) {
      align-items: center;
    }
  }

  @include maxw(1599) {
    &__header {
      &-subtitle {
        margin-bottom: 32px;
      }
    }
    &__list {
    }
    &__card {
    }
    &__footer {
    }
    &__brands {
    }
  }

  @include maxw(1279) {
    &__header {
      &-title {
        margin-bottom: 8px;
      }
      &-subtitle {
        margin-bottom: 24px;
      }
    }
    &__list {
      display: block;
    }
    &__card {
      padding: 24px;
      padding-right: var(--container-offset-X);
      padding-left: 108px;
      position: relative;
      min-height: 120px;
      &:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid $border-color;
      }
      &-img {
        position: absolute;
        left: 0;
        top: 12px;
        width: 96px;
        height: 96px;
        margin: 0;
      }
      &-title {
        margin-bottom: 8px;
      }
      &-text {
      }
    }
    &__footer {
    }
    &__brands {
    }
  }

  @include maxw(767) {
    &__header {
      &-action {
        display: none;
      }
    }
    &__list {
    }
    &__card {
    }
    &__footer {
      grid-gap: 24px;
      &-action {
        width: 100%;
        display: block;
      }
    }
    &__brands {
      &-prev,
      &-next {
        display: none;
      }
      &-slider {
        max-width: 100%;
      }
      &-item {
        :deep(img) {
          max-width: 100%;
        }
        &:not(:last-child) {
          padding: 0;
        }
        &--default {
          display: none;
        }
        &--mobile {
          text-align: center;
          display: grid;
          grid-gap: 36px 24px;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}
</style>
