<template>
  <div
    class="MassMediaCard"
    @click="onNewsItemClick"
  >
    <div class="MassMediaCard__img">
      <BaseImg
        :src="newsService.getImage(props.data.logoExternal) || 'images/banner-no-items-icons.svg'"
        format="webp"
        loading="lazy"
      />
    </div>
    <div class="MassMediaCard__title">
      {{ props.data.title }}
    </div>
    <div
      v-if="props.data.publicationDate"
      class="MassMediaCard__date"
      v-text="DateFormat.default(props.data.publicationDate)"
    />
  </div>
</template>

<script setup lang="ts">
import { IStrapiNewsListResponse } from '~/models/strapi/strapiNews.model';
import { DateFormat } from 'shared/utils/dateFormat.util';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { NewsService } from 'services/news/news.service';
import { useRequestUrl } from 'composables/useRequestUrl';

const props = defineProps<{
  data: IStrapiNewsListResponse;
}>();

const newsService = new NewsService(useRequestUrl());

function onNewsItemClick() {
  if (props.data.shortSourceUrl) {
    window.open(sanitizeUrl(props.data.sourceUrl), '_blank');
    return;
  }

  useRouter().push(`/news/${props.data.id}`);
}
</script>

<style lang="scss" scoped>
.MassMediaCard {
  background-color: $color-gray-extra-light;
  border-radius: 12px;
  padding: 24px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:after {
    content: '';
    flex-grow: 1;
  }

  &__img {
    height: 40px;
    max-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 48px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__title {
    @include text-m20;

    // TODO: переписать цвет на переменную из библиотеки, когда обновится максифай до 2.0.3 $color-green-dark-muted
    color: #214237;
    flex: 0 1 auto;
    margin-bottom: 20px;

    @include clamp(4);
  }

  &__date {
    @include text-r14;

    order: 1;
    color: $color-green-muted;
  }

  @media only screen and (max-width: 1279px) {
    &__img {
      margin-bottom: 32px;
    }
  }

  @media only screen and (max-width: 767px) {
    border-radius: 6px;
    padding: 16px;
    min-height: auto;

    &__img {
      margin-bottom: 20px;
      height: 32px;
    }

    &__title {
      @include text-r12;
    }
  }
}
</style>
